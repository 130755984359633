<template>
  <div class="query-group">
    <div class="group-content">
      <div class="group-option d-flex justify-space-between">
        <div class="logical-operator">
          <v-col cols="12" class="pa-0">
            <v-btn-toggle v-model="query.logicalOperator" color="deep-purple-accent-3" group>
              <v-btn value="AND">
                AND
              </v-btn>
              <v-btn value="OR">
                OR
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </div>
        <div class="pt-3">
          <v-btn
            type="button"
            depressed
            class="mr-2"
            color="#444"
            dark
            @click="addRule"
          >
            <v-icon
              left
              dark
            >
              mdi-plus
            </v-icon>
            Lisa reegel
          </v-btn>
          <v-btn
            type="button"
            depressed
            class="mr-2"
            color="#444"
            dark
            @click="addGroup"
          >
            <v-icon
              left
              dark
            >
              mdi-tab-plus
            </v-icon>
            Lisa grupp
          </v-btn>
          <v-btn
            v-if="delete_btn"
            type="button"
            depressed
            class="mr-2 delete"
            @click="deleteGroup"
          >
            <v-icon
              left
              dark
            >
              mdi-close
            </v-icon>
            Kustuta
          </v-btn>
        </div>
      </div>
      <div class="query-rules">
        <QueryRule
          v-for="(rule, index) in query.rules"
          :rule="rule"
          v-on:delete_rule="deleteRule(index)"
        ></QueryRule>
      </div>
      <div class="tooltip-recommendation">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="10"
          >
            <v-text-field
              label="Selgitus"
              outlined
              hide-details
              v-model="query.tooltip"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            <v-text-field
              label="Soovitusväärtus"
              outlined
              hide-details
              v-model="query.recommendation_value"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div class="query-groups">
        <QueryGroup
          v-for="(group, index) in query.groups"
          :query="group"
          :delete_btn="true"
          v-on:delete_group="removeGroup(index)"
        ></QueryGroup>
      </div>
    </div>
  </div>
</template>

<script>
  import QueryRule from './QueryRule';
  export default {
    name: "QueryGroup",
    components: { QueryRule },
    props: ["query", "delete_btn"],
    methods: {
      addRule: function () {
        let newRule = {
          "rule": "",
          "operator": "equal",
          "value": ""
        };
        this.query.rules.push(newRule);
      },
      deleteRule: function(index) {
        this.query.rules.splice(index, 1)
      },
      addGroup: function () {
        let newGroup = {
          logicalOperator: "AND",
          rules: [
            {
              "rule": "",
              "operator": "equal",
              "value": ""
            }
          ],
          tooltip: '',
          recommendation_value: 0,
          groups: []
        };
        this.query.groups.push(newGroup);
      },
      deleteGroup: function () {
        this.$emit('delete_group');
      },
      removeGroup: function (index) {
        this.query.groups.splice(index, 1)
      }
    }
  }
</script>