<template>
  <div class="query-rule">
    <div>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            label="Reegel"
            v-model="rule.rule"
            outlined
            hide-details
            :items="rule_options"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-select
            outlined
            v-model="rule.operator"
            hide-details
            :items="['equal', 'not equal', 'in']"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-if="rule.rule == 'insurer'"
            label="Selts"
            v-model="rule.value"
            outlined
            hide-details
            :items="insurances"
          ></v-select>
          <v-select
            v-else-if="rule.rule == 'risk'"
            label="Kaitse"
            v-model="rule.value"
            outlined
            hide-details
            :items="risks"
          ></v-select>
          <v-radio-group
            v-else-if="rule.rule == 'territory'"
            v-model="rule.value"
            row
            hide-details
          >
            <v-radio
              label="Euroopa, v.a SRÜ riigid"
              value="EU"
            ></v-radio>
            <v-radio
              label="Euroopa koos SRÜ riigidega"
              value="EU_PLUS"
            ></v-radio>
          </v-radio-group>
          <v-text-field
            v-else
            v-model="rule.value"
            label="Value"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="text-right"
        >
          <v-btn
            type="button"
            depressed
            class="mr-2 delete"
            @click="deleteRule"
          >
            <v-icon
              left
              dark
            >
              mdi-close
            </v-icon>
            Kustuta
          </v-btn>
        </v-col>

      </v-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: "QueryRule",
    props: ["rule"],
    data() {
      return {
        rule_options: [
          {
            text: 'Selts',
            value: 'insurer'
          },
          {
            text: 'Kaitse',
            value: 'risk'
          },
          {
            text: 'Kehtivuspiirkond',
            value: 'territory'
          },
          {
            text: 'Sõiduki tüüp',
            value: 'vehicle_type'
          },
        ],
        risks: [
          {
            text: 'Asendusauto',
            value: 'USE_INTERRUPTION'
          },
        ],
        insurances: [
          {
            text: 'BTA',
            value: 'BTA'
          },
          {
            text: 'PZU',
            value: 'PZU'
          },
          {
            text: 'LHV',
            value: 'LHV'
          },
          {
            text: 'IF',
            value: 'IfInsurance'
          },
          {
            text: 'Seesam',
            value: 'Seesam'
          },
          {
            text: 'Gjensidige',
            value: 'Gjensidige'
          },
          {
            text: 'Inges',
            value: 'Inges'
          },
          {
            text: 'Ergo',
            value: 'Ergo'
          },
        ]
      };
    },
    methods: {
      deleteRule: function() {
        this.$emit('delete_rule')
      }
    }

  }
</script>